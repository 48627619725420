<template>
  <div>
    <div>
      <section
        class="auction-list-page"
        style="
              padding: 15px 0px 15px;
              background-image: linear-gradient(to right, rgb(31 107 149), rgb(0 76 118));
        "
      >
        <div class="container">
          <div class="">
            <h5 style="color: white">Auction</h5>
            <span style="color: white" v-if="!showLongText">
              We are a neutral facilitator between the seller and the buyer and
              don't put any pressure on either party.
            </span>
            <span style="color: white" v-if="showLongText">
              We are a neutral facilitator between the seller and the buyer and
              don't put any pressure on either party.
              We just make the vehicles available. This means you don’t just pay
              fair prices, you pay the fairest price. How exactly? Thanks to us,
              you effectively bypass the greedy middle-men, maximizing margins
              on both the seller and the buyer side.
            </span> <span   @click="toggleText" style="color: #f8ed3e;cursor: pointer;" >{{ buttonText }}</span>
           
          </div>
        </div>
      </section>
      <!-- Auction tab -->
      <div class="text-center" style="display: table; margin: 12px auto 0px">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-Link filter-nav-Link " style="margin-right: 10px;">
            <div class="filter-icon" @click="toggleFilter">
              <i v-if="!isFilterOpen" class="fas fa-filter"></i>
              <i v-else class="fas fa-times"></i>
            </div>
          </li>
          <li class="nav-item">
            <a
              class="nav-link active"
              data-toggle="tab"
              href="#tabs-1"
              role="tab"
              >Live Auctions</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#tabs-2" role="tab"
              >Upcoming Auctions</a
            >
          </li>
        </ul>
        <!-- Tab panes -->
      </div>
      <div class="tab-content">
        <div class="tab-pane active" id="tabs-1" role="tabpanel">
          <carlist></carlist>
        </div>
        <div class="tab-pane" id="tabs-2" role="tabpanel">
          <upcommingauctions></upcommingauctions>
        </div>
      </div>

      <!-- <CarList>
      </CarList>  -->
    </div>
  </div>
</template>
<script>
//import CarList from "@/components/CarList";
export default {
  name: "Auction",
  data() {
    return {
      refresh: "false",
      showLongText: false, // Initially, hide the long text
      buttonText: "Read More",
      isFilterOpen: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    isMobileScreen: function () {
      return window.innerWidth <= 768; // You can adjust the width threshold as needed
    },
  },
  created() {
    if (this.loggedIn) {
      if (
        !this.$store.state.auth.user[0].customerId == "" ||
        !this.$store.state.auth.user[0].customerId == " "
      ) {
        this.$router.push("/Auction").catch(() => {
          "home";
        });
      } else {
        this.$router.push("/").catch(() => {
          "home";
        });
      }
    }
  },

  mounted() {
    // console.log(this.$store.state.auth.status.loggedIn);

    // filter-nav-Link

    if(!this.isMobileScreen){
      const vehicleFilter = document.querySelector('.filter-nav-Link');
      vehicleFilter.classList.add('hide-vehicle-filter');
    }


  },

  methods: {
    toggleText() {
      this.showLongText = !this.showLongText;
      this.buttonText = this.showLongText ? "Read Less" : "Read More";
    },
    toggleFilter () {
      this.isFilterOpen = !this.isFilterOpen;
      const vehicleFilter = document.querySelector('.vehicle-Filter');
      
      if (this.isFilterOpen) {
        vehicleFilter.classList.add('vehicle-filter-open');
        vehicleFilter.classList.remove('vehicle-filter-close');
      } else {
        vehicleFilter.classList.add('vehicle-filter-close');
        vehicleFilter.classList.remove('vehicle-filter-open');
      }
    },
  },
};
</script>
<style>
/* @media (min-width: 1400px) {
  .bidz-container-list {
    max-width: 1100px;
  }
} */

.filter-nav-Link{
  font-size: 20px;
}
.hide-vehicle-filter{
  display: none;
}
@media screen and (min-device-width: 768px) and (max-device-width: 991px) {
  .bidlistwidthfor768-991 {
    width: 33.33%;
  }
 
  .bidlistImagewrap {
    position: relative;
    height: 100%;
  }
  .bidlistImagewrap img {
    width: 100%;
    height: 60%;
    overflow: auto;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .bidBtn {
    width: 100%;
  }
  .hideon768-991 {
    display: none;
  }
  .bidzNumberz {
    background-color: #018f06;
    padding: 5px;
    color: white;
    border-radius: 5px;
    margin: 0px auto;
  }
  .detailsButtonz {
    background-color: gray;
    color: white;
    width: 100%;
    padding: 5px;
    border-radius: 5px;
  }
}

.bidzNumberz,
.detailsButtonz {
  display: none;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #ffffff !important;
  background-color: #050029;
  border-color: #dee2e6 #dee2e6 #fff;
  font-weight: 500;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  background-color: #858585;
  color: #ffffff !important;
}
</style>
